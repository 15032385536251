
.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 11;
  }
  .popup-in{
    position: relative;
    display:inline-block;
  }
  .popup-box {
    background-color: #fff;
    border-radius: 16px;
    padding: 30px 20px;
    width: 80%;
    height: auto;
    max-height: 60vh;
    text-align: center;
    z-index: 12;
    white-space: pre-line;
    display:inline-block;
    margin: auto;
    
  }
  .popup-content{
      width: 100%;
      max-width: 90vw;
      height: 100%;
      max-height: 50vh;
      overflow-y: auto;
      white-space:pre-line;
      z-index: 12;
      margin: auto;
      margin-bottom: 60px;
      
  }
  .popup-content::-webkit-scrollbar{
    width: 10px;
    display: block;
    /* color: #555;; */
  
  }
  .popup-content::-webkit-scrollbar-thumb {
    background-color: #ccc; /* 스크롤바 색상 설정 */
    border-radius: 3px; /* 스크롤바 모양 설정 */
    width: 10px;
    height: 10px;
  }
  
  .popup-content::-webkit-scrollbar-thumb:hover {
    /*background-color: #555;  스크롤바 호버 시 색상 설정 */
  }
  .popup-box-big {
    background-color: #fff;
    border-radius: 16px;
    padding: 20px;
    width: 80%;
    height: 100%px;
    text-align: center;
    white-space: pre-line;
    position: relative;

  }
  
  .popup h3 {
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 500;
  }
  
  .popup p {
    font-size: 1rem;
    margin-top: 10px;
  }
  
  .popup-button {
    width: 90%;
    height: 50px;
    color: #fff;
    border: none;
    border-radius:16px;
    font-size: 1rem;
    cursor: pointer;
    background-color: #328AE6;
    position: absolute;
    bottom: -60px;
    left: 50%;
    z-index: 999999;
    box-sizing: border-box;
    margin-bottom: 20px;
    transform: translate(-50%, -50%);
}


/* .popup-button {
  width: 90%;
  height: 50px;
  background-color: var(--sub-green-color);
  color: #fff;
  border: none;
  border-radius: 0.75rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: #328AE6;
  } */


#no-margin-bottom{
  margin-bottom: 0;
 } 

 #margin-top10{
  margin-top: 10px;
 }
 #margin-top20{
  margin-top: 20px;
 }

  .section-container{
    display: flex;
    flex-direction: column;
    padding:10px 20px ;
    position: relative;
 
  }
  .popup-button-sm1 {
    margin-right: 1rem;
    width: 50%;
    height: 50px;
    color: #328AE6;
    border: 1px solid #328AE6;
    border-radius:16px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    background-color: #fFF;

}
 .popup-button-sm2 {
    width: 50%;
    height: 50px;
    color: #fff;
    border: none;
    border-radius:16px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    background-color: #328AE6;
}

.popup-btn-box{
  display: flex;
  justify-content: space-around;

width: 100%;

  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup-btn-box-big{
  display: flex;
  justify-content: space-around;
width: 100%;

}


.popup {
  background-color: #fff;
  border-radius: 16px;
  padding: 30px 20px;
  max-width: 355px;
  text-align: center;
}

.popup h3 {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.popup p {
  font-size: 1rem;
  margin-bottom: 10px;
}

  .popup{
    box-sizing: border-box;
    position: fixed;
    top: 40% ;
    width: 400px;
    height: 226px;
    background: #FFFFFF;
    border: 1px solid #848484;
    border-radius: 16px;
    z-index: 7;
}
