input[type="text"] {
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    height : 48px;
    font-family: 'SCoreDream';
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
    margin-left: 0px;
    margin-top: 5px;
    margin: auto;
    padding: 5px 10px;


}
#zindex{
  z-index: 99;
}
#intro-search{
  z-index: 9;
  height : 50px;
  border: 2px solid #328AE6 !important;
  border-radius: 10px;
}

#nomagindiv{
  width: 0;
  margin: 0;
  height: 0;
}
.search-input-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #FFFFFF;
  border: 1px solid #C5C5C5;
  border-radius: 6px;
  box-sizing: border-box;
  width: 100%;
  padding: 0px 10px;
  margin: 0.85rem 0 auto;
  height: 48px;
  z-index: 9;
  padding-top: 1px;

}
input[type="search"]{

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  width: 100%;
  padding: 0px 10px;
  height : 46px;
  font-family: 'SCoreDream';
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  display: flex;
margin-bottom: 0.5rem;
  margin-left: 0px;
  padding-top: 0px;

  margin-top: 5px;
  margin: auto;
}

input[type="id"] {
    background: #fff;
        border: 1px solid #C5C5C5;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    height : 48px;
    padding-top: 5px;
    padding-left: 10px;
    font-family: 'SCoreDream';
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    line-height: 23px;
    padding-top: 5px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
    box-sizing : border-box;
    padding: 5px 10px;

}

input[type="password"] {
  background: #FFFFFF;
  border: 1px solid #C5C5C5;
  border-radius: 6px;
  box-sizing: border-box;
  width: 100%;
  height : 48px;
  font-family: 'SCoreDream';
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  line-height: 23px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
margin-bottom: 0.5rem;
  margin-left: 0px;
  margin-top: 5px;
  padding: 5px 10px;

  margin: auto;  
  
/*   
  background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    height : 48px;
    padding-left: 10px;  font-family: 'SCoreDream';
    font-style: normal;
    font-size: 1rem;
    padding-top: 5px;

    font-weight: 400;
    line-height: 23px;
    display: flex;
    align-items: center;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
    letter-spacing: 0.5px;
    box-sizing : border-box;
    margin-bottom: 1rem; */


}
input[type="id-checked"] {
    background: rgba(50, 138, 230, 0.1);
        border: 1px solid #ccc;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    height : 48px;
    padding-left: 10px;
    font-family: 'SCoreDream';
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    padding-top: 5px;
    padding-top: 5px;

    box-sizing : border-box;
  margin-bottom: 0.5rem;
  color: #ccc;
  padding: 5px 10px;

}

input[type="email"] {
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;

    height : 48px;
    padding-top: 5px;

    padding-left: 10px;
    font-family: 'SCoreDream';
    font-style: normal;
    font-size: 1rem;

    font-weight: 400;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
  padding: 5px 10px;


}

input[type="tel"] {
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    height : 48px;
    padding-left: 10px;
    font-family: 'SCoreDream';
    font-style: normal;
    padding-top: 5px;

    font-size: 1rem;
    font-weight: 400;
    line-height: 23px;
    display: flex;
    align-items: center;
    
    letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
  padding: 5px 10px;


}
input[type="id-check"] {
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding-left: 10px;
    font-family: 'SCoreDream';
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    line-height: 23px;
    display: flex;
    align-items: center;
    padding-top: 5px;

    letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
  padding: 5px 10px;

}

input[type="password"] {
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    height : 48px;
    padding-left: 10px;
    font-family: 'SCoreDream';
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    line-height: 23px;
    display: flex;
    align-items: center;
    padding-top: 5px;

    letter-spacing: 0.5px;
    /* margin-bottom: 1rem; */
  padding: 5px 10px;


}

label{
    font-family: 'SCoreDream';
font-style: normal;
font-weight: 500;
font-size: 1.05rem;
margin-bottom: 0.2rem;
line-height: 30px;
display: flex;
align-items: center;
letter-spacing: -0.5px;
flex-direction: column;
align-items: flex-start;
}

input[type="checkbox"] {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 1.2rem;
    height: 1.2rem;
    background: #FFFFFF;
    border: 1.5px solid #C4C4C4;
    border-radius: 50px;
    cursor: pointer;
    transition: background 0.2s;
  }
  

  input[type="checkbox"]::before {
    content: '\2713'; /* 체크 아이콘의 유니코드 값 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ccc;
    font-size: 8px;
    font-family: 'SCoreDream';
    transition: color 0.2s, opacity 0.2s;
    border-radius: 6px;
  }
  
  input[type="checkbox"]:checked{
    border-color: #328AE6;
    background-color: #328AE6;
}
input[type="checkbox"]:checked::before {
    color: #fff;
  }

  .dropdown-phone{
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    height : 48px;
    padding-left: 10px;
    font-family: 'SCoreDream';
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
  }
  
  .dropdown-phone-input{
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 6px;
    box-sizing: border-box;
    width: 90%;
    height : 48px;
    padding-left: 10px;
    font-family: 'SCoreDream';
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    /* margin-bottom: 1rem; */
  }

  select, option {
    font-weight: normal;
    display: block;
    white-space-collapse: collapse;
    text-wrap: nowrap;
    min-height: 1.2em;
    padding-right: 20px;
}

.input-label{
    font-size: 1.05rem;
    font-weight: 500;
    margin-bottom: 0.3rem;
    display: block;
}

#input6, #input4{
  margin-bottom: 0.5rem
}

#square-check{
  border-radius: 12px !important;
  margin: 1px 0  !important;
}

#eye-container{
  position: relative;
}

.eye-box{
  position: absolute;
  top: 58%;
  right: 0px;
  width: 30px;
  height: 30px;
}



input[type="radio"] + label::before ,#course-select-sm-3{
  border-color: #328AE6 !important; /* 일반 보더라인 색상 */
}

input[type="radio"]:checked + label::before {
  border-color: #328AE6  !important; /* 선택된 경우의 보더라인 색상 */
  background: rgba(50,138,230,.2)  !important;

}