
.click-big-wrap, .bottom-two-container-1{
    width: 100%;
    position: fixed;
    bottom: 0%;
    left: 50%; 
    transform: translateX(-50%);
    height: 4.2rem;
    background-color: #fff;
    z-index: 2;
    margin: auto;
    border: none;
}
.bottom-two-container-1{
    display: flex;
    padding: 10px 0 0px;
    width: 90%;
}
#blue-btn{
    background-color: #fff;
    border:1px solid #328AE6;
    color: #328AE6;

}

.click-big-button{
    position: fixed;
    bottom: 10%;
   left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height:  3.5rem;
    background-color:  var(--main-blue-color);
    border:  var(--main-blue-color);
    border-radius: 0.7rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight:  400;
    font-family: 'SCoreDream';
    font-stretch: expanded;
    transition: all ease .5s 0s;
    margin: auto;


}

#zindex{
    z-index: 3;
}

#buttonDisabled{
    background-color:  #ccc;
    color: #fff;
}
.bottom-button{
    position: absolute;
    bottom: 40%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 3.5rem;
    background-color: var(--main-blue-color);
    border:  var(--main-blue-color);
    border-radius: 0.7rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight:  400;
    font-family: 'SCoreDream';
    font-stretch: expanded;
    z-index: 3;
}
.bottom-container{
position: fixed;
width: 100%;
height: 160px;
bottom: 0px;
background: #fff;
filter: drop-shadow(0px -1px 17px rgba(0, 0, 0, 0.25));
border-radius: 30px 30px 0px 0px;
}
.bottom-buttons{
    position: relative;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 3.5rem;
    background-color: var(--main-blue-color);
    border:  var(--main-blue-color);
    border-radius: 0.7rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight:  400;
    font-family: 'SCoreDream';
    font-stretch: expanded;
    z-index: 3;
    
}

.bottom-two-container{
    position: fixed;
    width: 100%;
    height: 100px;
    bottom: 0px;
    background: #328AE6;
    filter: drop-shadow(0px -1px 17px rgba(0, 0, 0, 0.25));
    border-radius: 30px 30px 0px 0px;
    z-index: 2;   
}
    
.bottom-button-1{
    width: 50%;
    height: 100%;
    border-top-left-radius: 30px;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 400;
    font-family: 'SCoreDream';
    font-stretch: expanded;
    border-right: 1px solid #fff;


}
.bottom-button-2{
    width: 50%;
    height: 100%;
    border-top-right-radius: 30px;
    color: #fff;
    font-size: 1.2rem;
    font-weight:  400;
    font-family: 'SCoreDream';
    font-stretch: expanded;

}
.bottom-button-1{
}

.click-big-button-A{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 3.5rem;
    line-height: 3.5rem;
    background-color: var(--main-blue-color);
    border: var(--main-blue-color);
    border-radius: 0.7rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 400;
    font-family: 'SCoreDream';
    font-stretch: expanded;
    margin-bottom: 0.5rem;
}
.big-a-width{
    width: 90%;
    margin-top: 1rem;
    margin-bottom: 2rem;
}


.click-button-s{
    width: 100%;
    height: 48px;
    background-color: var(--main-blue-color);
    border: var(--main-blue-color);
    border-radius: 0.7rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 400;
    font-family: 'SCoreDream';
    font-stretch: expanded;
    margin-top: 20px;
}

.small-button{
    width: 10rem;
    height: 3.5rem;
    background: var(--main-blue-color);
    border-radius: 4rem;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    color: #fff;

}
.change-btn{
    width: 90%;
    margin-top: 1.7rem;
}
.change-btn-1{
    margin-top: 1.7rem;
}

.popup-button-m1{
    margin-right: 1rem;
    width: 50%;
    height: 60px;
    color: #328AE6;
    border: 1px solid #328AE6;
    border-radius:16px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    background-color: #fFF;
    z-index: 9999;

}

.popup-button-m2{
    width: 50%;
    height: 60px;
    color: #fff;
    border: none;
    border-radius:16px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    z-index: 9999;

    background-color: #328AE6;
}

/* 버튼 미디어 쿼리 */
@media (max-width: 290px) {
    /* 글씨크기 줄이기 */
    .bottom-button-1, 
    .bottom-button-2, 
    .click-big-button-A,
    .click-button-s,
    .click-big-button,
    .bottom-button,
    .bottom-buttons{
      font-size: 1.1rem;
    }
   
      }