


 ul, ol, li, dl, dt, dd, table, thead, caption, th, td{
	margin: 0;
	padding: 0;
}

.address-wrapper{
    /* position: absolute;
    bottom: 0; */
border-top: 1px solid #ccc;

	margin-top: 1rem;
}

.address-container{
width: 100%;
height: 100%;

}
.address-wrapper .toggle-header{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
#bottom-margin{
	margin-bottom: 20%;
}

.address-wrapper .toggle-body{
width: 100%;
height: 100%;
}
.address-top .text-4{
	font-weight:600;	
	margin-right:0.7rem;
}
.footer-toggle-body{
	margin-top: 0.5rem;
}
#toggle-header{
	align-items: center;
}
#toggle-header .text-4{
	margin-right:0.2rem;
}
.toggle-header{
	line-height: 20px;
	/* font-size: 1rem; */
	margin-left: 0.5rem;
}
/* a-style */
a {
	display:inline-block;
	color:inherit;
	text-decoration:none;
	cursor:pointer;
}
a:hover, a:active {
	cursor:pointer;
}



/* style */



.item1 {
	display:flex;
	width:1200px;
	margin:0 auto;
	padding:1rem 0;
}



.ftlogo {
	padding:1rem 3rem;
}
.ftlink a {
	line-height:4rem;
	font-size:1.1rem;
}
.ftlink a:before {
	content:"|";
	padding:1rem;
}
.ftlink a:first-child:before {
	content:"";
	padding:0;
}
.ftlink a:last-child {
	/* color:#ef7d00 */
    font-weight: 600;
}
address {
	font-style:normal;
	font-size:0.7rem;
	line-height:1rem;
	color:#1e1e1e;
}
address dl {
	display:flex;
	flex-wrap:wrap;
}
address dl{
	line-height:1rem;
	
}
address dl > dt {
	font-weight:600;
}
address dl > dd:after {
	content:"|";
	padding:0.5rem;
	vertical-align:top;
	color:#999999;
}
address dl > dd:last-child:after {
	content:"";
	padding:0;
}
address > p {
	line-height:3rem;
}


.address-top{
	display: flex;
	justify-content: space-between;
	}



	.stSlide{
	    /* padding-left: 50px;
	    padding-right: 50px; */
		width: 100%;
		object-fit: contain;
		margin: 20px auto;
	}

	.slide-img{
		width: 90%;
		object-fit: contain;
		border-radius: 16px;
	}