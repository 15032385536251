
/* Reset CSS */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: 'SCoreDream';
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}


ol,
ul,
li {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

button {
    background: inherit;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    overflow: visible;
    cursor: pointer;
    font-family: 'SCoreDream';
    color: #000;

}

a:link {
    color: #000;
    text-decoration: none;
    outline: none
}

a:hover,
a:active {
    text-decoration: none;
}

.App {
    font-family: 'SCoreDream';
    

}
button, html input[type=button], html input[type=text], html input[type=tel],html input[type=id] {
    -webkit-appearnce : none;
}

input:focus {
    background-color: transparent;
    outline: none;
}

input {
    outline: none;
    background-color:  transparent;
    margin: 0;
    -webkit-appearnce : none;
}

input::-ms-clear,
input::-ms-reveal{
	display:none;width:0;height:0;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration{
	display:none;
}

html, .App {
	/* height : calc(var(--vh, 1vh) * 100); */
    width: 100vw;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: hidden;

    -webkit-overflow-y-scrolling: touch !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
    -webkit-scrollbar {
    display: none !important;
  }  
}
body::-webkit-scrollbar {
    display: none !important;
  } 
/* iOS only 
@supports (-webkit-touch-callout: none) { 
    height : -webkit-fill-available;
}  */


select {
    color: #000;
   -webkit-appearance:none; 
     /* for chrome */
    -moz-appearance:none; 
     /*for firefox*/
     appearance:none;
     background: url('/public/img/common/down.png') no-repeat 96% 50%/10px auto;
    }
 
 select::-ms-expand{
    display:none;
    /*for IE10,11*/
    background: url('/public/img/common/down.png') no-repeat 96% 50%/10px auto;

 }

/* .App-contente{
overflow-y: auto;
} */

body {

    min-height: calc(var(--vh, 1vh) * 100);
    min-height:100dvh;
    height: 100vh;

    height: 100dvh;
    
    line-height: 1.5;
    margin: 0px auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;
    /* box-shadow: 10px 9px 44px 1px rgba(181,181,181,0.68); */
    /* -webkit-box-shadow: 10px 9px 44px 1px rgba(181,181,181,0.68);
    -moz-box-shadow: 10px 9px 44px 1px rgba(181,181,181,0.68); */
    /* overflow-y: auto; */
    -webkit-overflow-y-scrolling: touch !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
    -webkit-scrollbar {
    display: none !important;
  }  
  };

  body::-webkit-scrollbar {
    display: none;
}
html::-webkit-scrollbar {
    display: none;
}
 .App::-webkit-scrollbar {
    display: none;
}
  /* 주소창 없애기 */
  body { min-height:540px; }
    body[orient="portrait"] { min-height:540px; }
    body[orient="landscape"] { min-height:400px; }


 /* @media screen and (max-width: 768px) {
    body {
      overflow-y: ;
    }
  } */

#inner{
    width: 90%;
    margin: auto;
}


  code {
    font-family: 'SCoreDream';
  }
  /* @font-face {
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  }
  @import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
   */
  :root {
    --main-blue-color: #328AE6;  
    --sub-blue-color: rgba(50, 138, 230, 0.45);  
    --sub-gray-color: #C5C5C5;  
    --vh: 100%;
  }

  option {
    width: 90%;
  }
  textarea{
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    word-wrap :break-word;
    box-sizing: border-box;
    border: 1px solid #C5C5C5;
    font-family: 'SCoreDream';
    font-size: 1rem
  }

  /* @import url('https://webfontworld.github.io/SCoreDream/SCoreDream.css'); */
  @font-face {
    font-family: 'SCoreDream';
    font-weight: 100; 
    font-style: normal; 
    src: url(../public/assets/SCDream1.otf) format('opentype');
         
    font-display: swap;
}
@font-face {
    font-family: 'SCoreDream';
    font-weight: 200; 
    font-style: normal; 
    src: url(../public/assets/SCDream2.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'SCoreDream';
    font-weight: 300; 
    font-style: normal; 
    src: url(../public/assets/SCDream3.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'SCoreDream';
    font-weight: 400; 
    font-style: normal; 
    src: url(../public/assets/SCDream4.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'SCoreDream';
    font-weight: 500; 
    font-style: normal; 
    src: url(../public/assets/SCDream5.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'SCoreDream';
    font-weight: 600; 
    font-style: normal; 
    src: url(../public/assets/SCDream6.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'SCoreDream';
    font-weight: 700; 
    font-style: normal; 
    src: url(../public/assets/SCDream7.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'SCoreDream';
    font-weight: 800; 
    font-style: normal; 
    src: url(../public/assets/SCDream8.otf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'SCoreDream';
    font-weight: 900; 
    font-style: normal; 
    src: url(../public/assets/SCDream9.otf) format('opentype');
    font-display: swap;
}

.blind{
	visibility:hidden;
	position:absolute;
	top:-9999999px;
	left:-9999999px;
	width:1px;
	height:1px;
	margin:0;
	padding:0;
	background:none;
	font-size:0;
	line-height:0;
	text-indent:-9999999px;
}

button:disabled {
    color: #C5C5C5;
    border-color:#C5C5C5;

}