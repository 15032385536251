/* 게시판 css */
 
.notice-title {
  /* width: 400px; */
  height: 60px;
  line-height: 60px;
  margin-top: 25px;
  margin-bottom: 40px;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  color: #2c3540;
}

.notice-list {
  font-size: 0.875rem;
  width: 90%;
  text-align: center;
}

.notice-list > li {
  border-bottom: 1px solid #ccc;
}
.issue-list {
  font-size: 0.875rem;
  /* width: 1000px; */
  text-align: center;
}
.issue-list > li {
  border-bottom: 1px solid #ccc;
}
.notice-list > li:first-child {
  border-top: 1px solid #ccc;
}

.list-box {
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.board-lists {

  color: #2c3540;
}

.board-lists a {
  display: flex;
  flex-wrap: nowrap;
    text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.board-lists.title {
  display: flex;
  flex-wrap: wrap;
  border-top: 2px solid #2c3540;
  font-size: 0.875rem;
  background-color: rgba(102, 102, 102, 0.1);
}

.board-lists.title .list-title {
  text-align: center;
}

.board-lists.title p {
  background-color: rgba(102, 102, 102, 0.1);
  border-bottom: 1px solid #ccc;
}

.id-name {
  width: 10px;
}

.list-title {
  /* width: 700px; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  padding: 0 20px;
}

.list-title:hover {
  cursor: pointer;
}

.list-author {
  width: 100px;
}

.list-date {
  width: 150px;
}

.list-view {
  width: 100px;
}

.inquireBtn {
  right: 0;
  bottom: 5%;
  padding: 7px 16px;
  position: absolute;
  border-radius: 0.25rem;
  color: #fff;
  background: #3b4b59;
  font-size: 0.87rem;
  margin-right: 0;
}

.loginBtn {
  display: block;
  right: 7%;
  bottom: 5%;
  padding: 7px 16px;
  position: absolute;
  border-radius: 0.25rem;
  color: #fff;
  background: #3b4b59;
  font-size: 0.87rem;
}

.writeBtn {
  right: 0;
  bottom: 5%;
  padding: 7px 16px;
  position: absolute;
  border-radius: 0.25rem;
  color: #fff;
  background: #3b4b59;
  font-size: 0.87rem;
}

.board-search-container {
  /* width: 1200px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 20px; */
  border-radius: 15px;
}

.btn-success {
  margin-left: -26px;
}

.board-search-selector {
  width: 70px;
  height: 40px;
  margin-right: 10px;
}

.input-box {
  width: 250px;
  height: 40px;
  font-size: 1rem;
} 
/* 디테일 */
#NoticeDetail{
  margin-top: 3rem;
}
.NoticeDetail{
   width: 100%;}



.detail-container {
  margin-bottom: 1rem;
  width: 100%;
  /* width: 350px; */

}
.detail-container table {
  width: 100%;
    /* border-left: 1px solid #ccc;
  border-right: 1px solid #ccc; */
  border-collapse: collapse;
  text-align: left;
}

.detail-container table th,
.detail-container table td {
  /* border-bottom: 1px solid #ccc; */
  font-size: 16px;
}


.detail-container-header-content {
  display: flex;
  justify-content: space-between;
  padding: 0 0 10px 0px ;
  flex-direction: row;
  overflow-wrap: break-word;
  word-wrap:normal;
  /* background-color: rgba(204, 204, 204, 0.2); */
  border-bottom: 1px solid #ccc;
  width: 90%;
  margin: auto;

}
.detail-container-header-title {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-between;
  padding: 10px 0 5px 0px ;
  flex-direction: row;
  overflow-wrap: break-word;
  word-wrap:normal;
  font-size: 1.1rem;
  font-weight: bold;
  /* background-color: rgba(204, 204, 204, 0.2); */

}

.detail-container-header-author::before, 
  .detail-container-header-date::before {
  content: "|";
  padding-right: 0.5rem;
  vertical-align: top;
  color: #999999;
}

/* .detail-container-header-contents {
  padding: 40px 80px;
  height: auto;
line-height: 1.8; 
} */
.detail-sample-img {
  width: 100%;
}

.inquiry-Btnbox {
  display: flex;
  justify-content: flex-end;
}
.rewriteBtn {
  padding: 0px 16px;
  border-radius: 0.25rem;
  color: #fff;
  background: #3b4b59;
  font-size: 0.87rem;
  margin-right: 10px;
}
.detail-first-list {
  padding: 7px 16px;
  border-radius: 0.25rem;
  color: #fff;
  background: #3b4b59;
  font-size: 0.87rem;
}
.detail-first-list-link {
  text-align: center;
  width: 100%;
}

/* ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ */
/* 미디어 쿼리 */
/* ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ */

/* @media screen and (max-width: 765px) { */

  .notice-title {
    width: 95%;
    font-size: 1.1rem;
    height: 1.2rem;
    line-height: 1.2rem;
    margin-top: 0px;
    margin-bottom: 0px;
  font-weight: 500;
  display: none;
  }

  .notice-list {
    width: 100%;
    margin: 0 auto;
  }
  .link-box {
    width: 100%;
  }

  .board-lists {
    width: 100%;
    height:100%;
    margin: 0 auto;
    padding: 5px 10px 7px 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1rem;
  }
  .board-lists.title {
    display: none;
  }
  /* .detail-container-contents{
    width: 100%;
    text-align: left;
  }
  .detail-container table th {
    font-size: 1rem;
    line-height: 1.8rem;
    margin: 0 auto;
    padding-right: 20px;
    vertical-align: middle;
  } */
  /* .detail-container table td {
    font-size: 1rem;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  } */
  .id-name {
    margin: 0;
    padding: 0;
    margin-right: 1rem;
    font-size: 1rem;
  }
  .id-box{
    width: 30px;

  }
  .list-title {
    width: 90%;
    padding: 0;
    font-size: 1rem;
    font-weight: 500;
    height: 1.5rem;
    line-height: 1.5rem;
    margin: 0;
    padding: 0;

  }
  .list-author {
    width: 50px;
    font-size: 0.8rem;
    font-weight: 400;
    text-align: left;
    height: 28px;
    line-height: 30px;
    display: none;
  }
  .list-date {
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
    text-align: left;
    height: 1rem;
    line-height: 1.2rem;
  }
.flex-column{
  display: flex;
  flex-direction: column;
  width: 100%;
}
  .list-view {
    display: none;
  }
  .writeBtn {
    bottom: 4%;
    right: 3%;
    font-size: 0.6rem;
  }
  .loginBtn {
    bottom: 4%;
    right: 22%;
    font-size: 0.6rem;
  }
  .detail-container-header-contents {
    padding-left: 10px;
    padding-right: 10px;
    height: auto;
    line-height: 1.6;
    text-align: left;
    vertical-align: middle;
    width: 90%;
    margin:10px auto;
    /* width: 350px !important; */


  }
.detail-container-header-contents>p{
  /* width: 350px !important; */
}
  .detail-container-header-author {
    font-size: 0.9rem;
    text-align: center;
  
  }
  .detail-container-header-date {
    font-size: 0.9rem;
  }
  .detail-container-header-view {
    font-size: 0.9rem;
  }


  .detail-first-list {
    font-size: 0.7rem;
    margin: 0rem auto 3rem ;


  }
/* } */
  
/* 
.paginationBox{
  margin: 0.5rem auto;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PageNumButton{
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  padding-bottom: 0.2rem;
}
.NextButton:active {
  border: solid 1px #3b4b59;
} */


/* 페이지네이션 */
.paginationBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  padding-top: 1rem;
}

.PrevButton {
  border-radius: 0.25rem;
  color: #3b4b59;
  width: 40px;
  height: 24px;
  font-size: 1.5rem;
}

.PrevButton:hover {
  cursor: pointer;
  background: #e0e0e0;
}

.PrevButton:active {
  border: solid 1px #3b4b59;
}

.NextButton {
  border-radius: 0.25rem;
  color: #3b4b59;
  width: 40px;
  height: 24px;
  font-size: 1.5rem;
}

.NextButton:hover {
  cursor: pointer;
  background: #e0e0e0;
}

.NextButton:active {
  border: solid 1px #3b4b59;
}

.PageNumButton {
  border-radius: 0.25rem;
  width: 20px;
  height: 24px;
  color: #3b4b59;
  background: #fff;
  font-size: 1rem;
}

.PageNumButton:hover {
  cursor: pointer;
  /* background: #e0e0e0; */
  color: #000;
  font-size: 1.05rem;
}

.PageNumButton:active {
  border: solid 1px #3b4b59;
}

/* @media screen and (max-width: 765px) { */
  .paginationBox {
    width: 50%;
  }
  .PrevButton {
    width: 95%;
  }
  .NextButton {
    width: 95%;
  }
  .PageNumButton {
    width: 95%;
  }
/* } */

.prev-next-links{
  display: flex;
  flex-direction: column;
  width: 100%;
margin: 0 auto 1rem auto;
  align-items: flex-start;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;

}

  .prev-link, .next-link{
    /* padding: 5px 0px; */
    text-align: center;
    display: flex;
    flex-direction: row;
    width: 100%;

    align-items: center;
  }
  
  .prev-link>p, .next-link>p{
    padding: 5px 0px;
    margin-right: 12px;
    /* text-align: center; */
    white-space: nowrap;
    text-overflow:ellipsis;
    overflow: hidden;
    width: 100%;
    text-align: left;
    
  }
  .prev-link{
    /* border-bottom: 1px solid #ccc; */
  }

  #arrow-next{
margin-right: 5px;
margin-left: 15px;
width: 5%;
color: #999999;
}