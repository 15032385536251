.sideNavigation {
  position: fixed;
  top: 0;
  right: -255px; /* 0 */
  width: 250px;
 max-height: 110vh;
  min-height: 100%;
  touch-action: none;
  background-color: #FFFFFF;
  transition: right 0.3s ease-in-out;
  z-index: 999999;
  border-left: 1.0643px solid #71839B;
  overflow-y: auto;
}

.sideNavigation::-webkit-scrollbar {
  display: none;
}
.open {
  right:0;
}

.sideNaviBackground{
    content: "";
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
      
}
/* 
.side-navigation {
    width: 0;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    overflow-x: hidden;
    transition: width 0.5s;
    background-color: #fff;
  } */
  .side-navigation>.toggle-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sideNavigation .hamburger{
    position: relative;
    right: 10px;
  }

  .toggle-button {
    margin-top: 1rem;

    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    padding: 0 10px;
  color: #324054;

  }
  
  .user-greeting {

    color: #324054;
    padding: 10px;
  }
  
  .horizontal-line {
    width: 90%;
    height: 1px;
    background-color: #324054;;
    color: #324054;
 
    margin: 10px auto;
  }
  
  .menu-list{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-list li, .settings, .logout {
    display: flex;
    align-items: center;
    color: #324054;
     padding: 10px;
     margin-left: 10px;
  }
  .menu-list li>button{
    display: flex;
    align-items: center;
    width: 100%;
  }

  .menu-list li a {
    text-decoration: none;
    color: #324054;;
    display: flex;
    align-items: center;
  }
  
  .side-text {
    margin-left: 10px;
    display: p;
  }
  .settings,
  .logout {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .settings a,
  .logout a {
    text-decoration: none;
    color: #324054;;
    display: block;
  }
  
  .settings i,
  .logout i {
    font-size: 20px;
  }
  
  .side-navigation.open .toggle-button {
    justify-content: flex-start;
  }
  
  .side-navigation.open .toggle-button i {
    transform: rotate(180deg);
  }
  
  .side-navigation.open .horizontal-line {
    width: 90%;
  }
  
  /* .menu-list li:hover {
    background-color: #e6f0ff;
    width: 80%;
    height: 100%;
    border-radius: 8px;
    color: var(--main-blue-color);

  }
   */
  
  .logout {
    margin-top: auto;
  }


  .user-text{
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    padding: 10px;
  color: #324054;
  margin-left: 10px;
  font-style: normal;
font-weight: 500;
font-size: 1.2rem;
line-height: 17px;
  }

.copyright-txt{
  font-size: 0.75rem;
  font-display: center;
  padding: 10px 0px 0px 10px;
}

.side_overlay{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0
}

.close{
  display: none;
}