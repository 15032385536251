.chat-btn{
    /* position: fixed;
    top: 10px;
    right: 5%; */
    z-index: 9999;
}
.chatbot-icon{
width: 100%;
height: 40px;
}
.chatbot-area{
    height: 40px;
    vertical-align: middle;
}
.chatbot-script{
    display: none;
}

#chat-frame{
    display: none;

}
/* .chat-btn {
    cursor: grab;
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
   */
  .chat-btn:active {
    cursor: grabbing;
  }

  #topnav-chat{
/* vertical-align: middle; */

  }

  