.modalContainer {
    width: 100%;
    height: auto;
    padding: 2rem 0;
    background: #fff;
    border-radius: 2rem 2rem 0 0;
    text-align: center;
    position: fixed;
    bottom: -20rem;
    left: 0;
    z-index: 99999;
    transition: 0.2s;
    overflow-y: auto; /* 세로 스크롤을 허용 */
  }

  
  .modalContainer.modalView {
    bottom: 0;
  overflow: hidden;
  }
  
  body.modalView {
    overflow: hidden; /* 전체 화면 스크롤을 막음 */
  position: fixed;

  }
  .text {
    font-size: 1.3rem;
    font-weight: 500;
    color: #060F27;

  }
.text-label{
  font-size: 1.05rem;
    font-weight: 450;
    color: #060F27;
    margin: auto;
    margin-bottom: 5px;
    margin-top: 15px;
}

#input1312{
  margin-bottom: 1rem;
}

.error-message-option{
  color: #F00;
  font-size:  0.75rem;
  font-weight: 400;
  margin-left: 0.3rem;
  margin-bottom: 0.5rem;
  margin-top: -0.7rem;
}
 
#option-modal{
  text-align: left;
  justify-content: flex-start;
  max-height: 81vh;
}

  #option-modal-kgl{
    text-align: left;
    justify-content: flex-start;
  }
/* }
 #option-select{
    width: 90%;
    margin: auto;
    margin-top: 10px;
    font-style: normal;
    font-family: 'SCoreDream';
    font-weight: 400;
    font-size: 1rem;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    flex-direction: column;
    align-items: flex-start;
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding-left: 10px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    text-align: center;
  } */

  .modalBackground {
    content: "";
    display: none;
    width: 100%;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
  }
  
  .modalBackground.modalBackgroundView {
    display: block;
  }

  .modal-inner{
    width: 90%;
    margin: auto;
    overflow-y: auto;
  }
.buttonContainer{
    display: flex;
    flex-direction: column;
      justify-content: center;
      margin-top: 1rem;
      width: 100%;
}
.button-container{
  margin: 20px auto 10px auto;
  display: flex;
  justify-content: center;
  gap: 10px;
}
  #basket-btn{
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    height: 60px;
    font-size: 1.2rem;
    color: #060F27;
    width: 100%;
  }
  #basket-btn:hover{
    background-color: rgba(50, 138, 230, 0.45);

  }
  #basket-btn:active{
    background-color: rgba(50, 138, 230, 0.45);

  }

  #basket-btn>img{
    width: 26px;
  }

 #basket-btn-img:last-child{
    width: px;
 } 
 .modal-container{
  width: 100%;
  height: 20rem;
  padding: 3.7rem 0;
  background: #fff;
  border-radius: 2rem 2rem 0 0;
  text-align: center;
  position: fixed;
  bottom: -30rem;
  left: 0;
  z-index: 99999;
  transition: 0.2s;
 }
 .check-list{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
 }
 .ec-sel-checkbox{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.check-list>label{
  font-style: normal;
    font-family: 'SCoreDream';
    font-weight: 400;
    font-size: 1rem;
}
#option-tip-text{
  padding-top: 0.2rem;
  color: red;
}

.react-datepicker__input-container .react-datepicker__calendar-icon{
top: 15%;
fill: #c5c5c5;
margin-right: 10px;
}

.date-between{
 padding: 0 0.2rem;
  width: 10px;
}

#bottom-margin-10{
  margin-bottom: 10px;
}


.react-datepicker-popper[data-placement^=bottom] {
  margin-top: -38%;
}

.custom-input-button{
  background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 6px;
    width: 48%;
    box-sizing: border-box;
    height: 48px;
    font-family: 'SCoreDream';
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    margin-bottom: 0.5rem;
    margin-left: 0px;
    margin-top: 5px;
    margin: auto;
    padding: 5px 10px 5px 30px;

}
.date-picker{
  display: flex;
  flex-direction: row;  
  align-items: center;
  justify-content: space-between;
  width: 100%

}
.date-picker .custom-input-button {
  width: 100%
}
.react-datepicker__input-container{
  display: flex;
}
.react-datepicker-wrapper{
  width: 100% !important;
}
@media screen and (max-width: 379px) {
  .select-round-p {
    font-size: 14px;
    word-break: break-all;
  }
}
.react-datepicker-popper{
  z-index: 999 !important;
}

.bottom-modal-container{
  margin-top: 10px;
}

.option-box-bottom {
  margin-bottom: 0px !important;
}