.finish-screen {
    position: relative;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255, 0.8);
    z-index: 99;
  }
  
  #loading-container {
position: absolute;
top: 45%;
    left: 50%;
margin: auto;

    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.loding-div{
  width: 100%;
margin: auto;

}
.loading-info{
margin: auto;
text-align: center;
font-size: 1.1rem;

}
  
  .loading-gif {
    width: 70px; /* 로딩 이미지의 크기를 조정해주세요 */
    height: 70px;
text-align: center;
margin: auto;
  }