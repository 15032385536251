.navbar-container {
  width: 100%;
  height: 3.3rem;
  background: #fff;
  border-bottom: 1px solid #555555;
  position: fixed;
  top: 0;
  z-index: 10;
}
.navbar-inner{
  width: 92%;
  margin: auto;
  height: 3.3rem;
  background: #fff;
  display: flex;
  font-size: 1.6rem;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid #555555;
 
}

.nav {
  cursor: pointer;
  align-items: center;
  padding-top: 10px;
  z-index: 10;
  
}

.arrow-icon {
  font-size: 1.6rem;
  
  text-align: center;
  align-items: center;
  z-index: 10;

}

/* .logo-box{
  flex-basis: 50%;
} */
.menu-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-family:  'SCoreDream';
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height:2.5rem;
  z-index: 10;
  padding-top: 5px;
  text-align: center;
}
/* #issue-top-menu{
  padding-top: 0px;
} */

/* 로고 바 */

.logobar-container {
  width: 100%;
  height: 3rem;
  background: #fff;
  display: flex;
  font-size: 1.6rem;
  align-items: center;
  justify-content: space-between;
  justify-content: center;
  z-index: 10;

}

.logobar-containe>.logo{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

}

.chat-btn-main{
  width: 100%;
}
/* #main-logo-box{
grid-row: 2;
} */
@media (max-width: 290px) {
  .menu-title{
    font-size: 1.25rem !important;
    left: 30%;
    transform: translateX(-30%);
  }   
      
    }