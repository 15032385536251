
.main-layout{
  width: 90%;
  margin: 20px auto;
  overflow-x : hidden;
  /*터치 스크린 안먹힘 touch-action: none; */
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /* padding-bottom: 2rem; */
  margin-bottom: 2rem;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-size:0.85rem;
  &::-webkit-scrollbar {
  display: none;
}  
}

  /* 헤더 */
  .main-layout .header{
  display: flex;
  justify-content: space-between;
  height: 60px;
  margin-bottom: 10px;
  position: relative;
}
/* .main-header{
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  align-items: flex-start;
} */

#main-header{
  margin-top:0px;
}
.main-layout .logo>img{
  width: 120%;
  display: block;
  box-sizing: border-box;
  background-color: #fff;

}

.main-layout  .logo {
  width: 180px;
    background-color: #fff;
    object-fit: contain;

  }

/* 메뉴버튼 */
.menu-container{
  width: 100%;
  margin: auto;
  margin-top: 0rem;
  margin-bottom: 1rem;
}

.main-layout .detail-tab-list, .tab-button{
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height:1.2rem;
    width: 45%;
    height: 40px;
    border-bottom: 2px solid #328AE6;
    color: #328AE6 ;
    /* border-bottom: 1px solid #D9D9D9;
    margin-left: 10px; */

}

.detail-tab-list.active{
    border-bottom: 2px solid #328AE6;
    color: #328AE6 ;
}

.banner{

    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    margin: 20px auto;
    border-radius: 15px;
}

.banner>img{
  width: 100%;
  object-fit: contain;
  margin: 20px auto;
}

.menu-buttons{
display: grid;
grid-template-columns: 48% 48%;
gap: 2% 4%;    
}

.menu-button{
    width: 100%;
    height: 100px;
    border-radius: 16px;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'SCoreDream';
    font-style: normal;
    font-weight: 500;
    font-size: 0.95rem;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
  /* margin-left: 2%; */
  }
.menu-button>img{
  width: 45px;
  height: 45px;
  object-fit: contain;
  padding-bottom: 10px;
}

#login-hamburger{

  object-fit: contain;
}
.icon-container {
  width: 20px;
  height: 20px;
  text-align: center;
}

#btn-1{
  background-color: #116AC7;
}
#btn-2{
  background-color:  #5E72DA;
}
#btn-3{
  background: rgba(91, 118, 255, 0.82);
}
#btn-4{
  background-color: #6291F0;
}
#btn-5{
  background-color: #509AD4
}
#btn-6{
  background-color: #3A9FC9;
}

#btn-1>img, #btn-5>img{
  width: 40px;
}

.bottom-menu{
  position: fixed;
width:100%;
height: 60px;
left: 0px;
bottom: 0px;
display: flex;
align-items: center;
justify-content: space-around;
border-top: 1px solid #ccc;
border-top-left-radius: 16px;
border-top-right-radius: 16px;
box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
background-color: #fff;

}
.bottom-menu-button{
  width: 50px;
  height: 50px;
}

#bottom-btn-img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.center-plus{
  width: 45px;
  height: 45px;
  margin-top: 3%;
}
.alarm-icon{
  width: 28px;
    height: 26px;
    object-fit: contain;
}
#book-icon{
  margin-top: 5%;
  width: 30px;
  height: 30px;
  object-fit: contain;
}

